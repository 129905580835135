<template>
  <div :class="$style.illustration">
    <div :class="$style.figure">
      <UiBaseIcon
        :name="iconFrom"
        :class="[
          $style.icon,
          connecting && $style.icon_connecting,
          connected && $style.icon_connected,
        ]"
      />
    </div>
    <UiBaseIcon name="arrows-transfer-down" :class="$style.divider" />
    <div :class="$style.figure">
      <UiBaseIcon
        :name="iconTo"
        :class="[$style.icon, relatedDevice && $style.icon_connected]"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
defineOptions({ name: 'ModeIllustration' })

type DeviceType = 'client' | 'scanner'

const props = defineProps({
  from: null,
  to: null,
  connecting: { type: Boolean },
  connected: { type: Boolean },
  relatedDevice: { type: Boolean }
})

const icons: Record<DeviceType, (active: boolean) => string> = {
  client: (active) => (active ? 'device-imac' : 'device-imac-x'),
  scanner: (active) => (active ? 'device-mobile' : 'device-mobile-x'),
}

const iconFrom = computed<string>(() =>
  icons[props.from](props.connecting || props.connected),
)

const iconTo = computed<string>(() => icons[props.to](props.relatedDevice))
</script>

<style lang="scss" module>
.illustration {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 12px;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 320px;

  > * {
    flex: 0 0 auto;
  }
}

.figure {
  padding: 16px;
  border-radius: 50%;
  box-shadow: 0 3px 7px 0 rgb(0 0 0 / 0.1);
}

.divider {
  width: 32px;
  transform: rotate(90deg);
}

.icon {
  width: 100%;
  color: var(--danger-color);
  transition: color 0.3s;
  stroke-width: 1px;

  &_connecting {
    color: var(--info-light-color);
  }

  &_connected {
    color: var(--accent-color);
  }
}
</style>
